import {
  CurrentWorkspaceResponseFeaturesEnum as Features,
  UserRoleEnum as Role,
} from 'herald-fe-shared';

import { hooks } from 'lib';

export const useIsExposed = () => {
  const { data: active } = hooks.useWorkspace();
  return active?.features?.includes(Features.Exposed) || false;
};

export const useSuggestTopics = () => {
  const { data: active } = hooks.useWorkspace();
  return active?.features?.includes(Features.TopicSuggestion) || false;
};

export const useIsViewer = () => {
  const { data: me } = hooks.useMe();
  return me?.user.role === Role.Viewer;
};

export const useCanEdit = () => {
  const { data: me } = hooks.useMe();
  const isViewer = useIsViewer();
  return !isViewer || me?.rookie ? true : false;
};
