import React, {
  useMemo,
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';
import {
  colors,
  components,
  styles,
  InternalContactTypeEnum,
  CustomAttributeSourceEnum as Source,
  InternalContactSourceEnum as ContactSource,
  CustomAttributeTypeEnum as Type,
} from 'herald-fe-shared';

import { SnippetContext } from 'components/context/SnippetWrapper';
import { ModalContext, ModalFooter } from 'components/context/ModalWrapper';
import { environment, setShortcut, hooks, api } from 'lib';

import ExternalLink from 'components/ExternalLink';
import Dropdown from 'components/SimpleDropdown';

const {
  ContactName,
  ContactEmail,
  ContactPhone,
  ContactProspectToggle,
} = components.forms;

const StyledModal = styled.div`
  height: 100vh;
  .header {
    padding: 0 2rem;
    height: 5rem;
    border-bottom: ${styles.BORDER};
    display: flex;
    align-items: center;
  }
  .content {
    overflow: auto;
    height: calc(100vh - 9rem);
  }
  .content__message {
    margin: 0;
    padding: 1rem 2rem;
    padding-bottom: 0;
  }
  .attribues-message {
    margin: 1rem 2rem;
    margin-top: 2rem;
    padding: 1rem;
    width: calc(100% - 2rem);
    border-radius: 4px;
    background: ${colors.GRAY_2(0.4)};
  }
  .form-group {
    padding: 0 2rem;
    margin-top: 2rem;
  }
  .input__container {
    height: 3rem;
  }
  input {
    font-size: 1.2rem;
  }
`;

const UNEDITABLE_SOURCES = [ContactSource.Intercom, ContactSource.API];

const ContactEditModal: React.FC = () => {
  const { contact, setContact } = useContext(SnippetContext);
  const { close } = useContext(ModalContext);
  const { data: attributes } = hooks.useAttributes();
  const { data: contactDetails, revalidate } = hooks.useContact(contact?.id);
  const { data: workspace } = hooks.useWorkspace();

  const [custom, setCustom] = useState<any>(contact?.custom || {});
  const [name, setName] = useState(contact?.name || '');
  const [email, setEmail] = useState(contact?.email || '');
  const [phone, setPhone] = useState(contact?.phone || '');
  const [prospect, setProspect] = useState(
    contact?.type === InternalContactTypeEnum.Prospect || false
  );

  const nameRef = useRef(null);

  const contactCustom = useMemo(() => contactDetails?.customer.custom, [
    contactDetails,
  ]);
  const contactSource = useMemo(() => contactDetails?.customer.source, [
    contactDetails,
  ]);

  const disableProps = useMemo(
    () => UNEDITABLE_SOURCES.includes(contactSource as any),
    [contactSource]
  );

  useEffect(() => {
    if (contactCustom) {
      const newCustom = {};
      contactCustom.forEach((c) => {
        newCustom[c.attributeId] = c.value;
      });
      setCustom(newCustom);
    }
  }, [contactCustom]);

  const onSave = useCallback(async () => {
    setContact({
      ...contact,
      isNew: contact?.isNew || false,
      name,
      email,
      phone,
      custom,
      type: prospect
        ? InternalContactTypeEnum.Prospect
        : InternalContactTypeEnum.Customer,
    });
    if (contact && contact.id && contactCustom) {
      const customer = contactDetails?.customer;
      const multiCustom = Object.keys(custom)
        .filter((attributeId) => {
          const customValue = contactCustom.find(
            (c) => c.attributeId === attributeId
          );
          return (
            custom[attributeId] !== undefined &&
            customValue &&
            customValue.value !== custom[attributeId]
          );
        })
        .map((attributeId) => ({
          attributeId,
          value: custom[attributeId],
        }));
      const type = prospect
        ? InternalContactTypeEnum.Prospect
        : InternalContactTypeEnum.Customer;
      const update = {
        id: contact.id,
        name: name && customer.name !== name ? name : undefined,
        email: email && customer.email !== email ? email : undefined,
        phone: phone && customer.phone !== phone ? phone : undefined,
        type: customer.type !== (type as any) ? (type as any) : undefined,
        multiCustom: multiCustom?.length ? multiCustom : undefined,
      };
      if (
        Object.keys(update).filter((u) => u !== 'id' && update[u] !== undefined)
          .length
      ) {
        await api.update.customer(update);
        revalidate();
      }
    }
    close();
  }, [
    close,
    contact,
    setContact,
    name,
    email,
    phone,
    prospect,
    custom,
    contactCustom,
    contactDetails,
    revalidate,
  ]);

  useEffect(() => {
    setShortcut('SAVE', onSave);
  }, [onSave]);

  useEffect(() => {
    if (nameRef && nameRef.current && !disableProps) {
      setTimeout(() => (nameRef.current as any).focus(), 250);
    }
  }, [nameRef, disableProps]);

  const customAttributes = useMemo(
    () => attributes?.filter((a) => a.source === Source.Herald) || [],
    [attributes]
  );

  return (
    <StyledModal>
      <div className="header">
        <h4>Edit Contact</h4>
      </div>
      <div className="content">
        {disableProps && (
          <p className="content__message">
            This contact is synced via {(contact as any)?.source}, so only
            custom attributes can be changed.
          </p>
        )}
        <ContactName
          name={name}
          setName={setName}
          horizontal={!environment.front}
          inputProps={{ ref: nameRef }}
          disabled={disableProps}
        />
        <ContactEmail
          email={email}
          setEmail={setEmail}
          horizontal={!environment.front}
          disabled={disableProps}
        />
        <ContactPhone
          phone={phone}
          setPhone={setPhone}
          horizontal={!environment.front}
          disabled={disableProps}
        />
        <ContactProspectToggle
          prospect={prospect}
          setProspect={setProspect}
          horizontal={!environment.front}
          disabled={disableProps}
        />

        {customAttributes.length > 0 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '2rem',
              marginTop: '3rem',
            }}
          >
            <h4 className="text-gray" style={{ marginRight: '1rem' }}>
              Custom Attributes
            </h4>
            <ExternalLink
              url={`https://app.heraldhq.com/w/${workspace?.slug}/contacts/custom-attributes`}
            >
              <components.Button secondary={true}>Edit</components.Button>
            </ExternalLink>
          </div>
        )}
        {customAttributes.map((a) => {
          const onChange = (e: any) => {
            const newCustom = { ...custom };
            newCustom[a.id] = e;
            setCustom(newCustom);
          };
          const label = (
            <div
              style={{
                display: 'flex',
                flexFlow: 'row wrap',
              }}
            >
              <label>{a.name}</label>
            </div>
          );
          if (a.options) {
            return (
              <components.FormGroup key={a.id} label={label} horizontal={true}>
                <Dropdown
                  placeholder="None"
                  options={a.options as any}
                  value={custom[a.id]}
                  onChange={onChange}
                />
              </components.FormGroup>
            );
          }
          return (
            <components.FormGroup key={a.id} label={label} horizontal={true}>
              <components.Input
                value={custom[a.id] || ''}
                onChange={(e) => onChange(e.target.value)}
                inputProps={{
                  placeholder: 'None',
                  type: a.type === Type.String ? 'text' : 'number',
                }}
              />
            </components.FormGroup>
          );
        })}
      </div>
      <ModalFooter>
        <components.ButtonGroup right={true}>
          <components.Button secondary={true} onClick={close}>
            Cancel
          </components.Button>
          <components.Tooltip
            message={
              <>
                <code>{environment.mac ? '⌘' : 'Ctrl'}</code>
                <code>Enter</code>
              </>
            }
          >
            <components.Button onClick={onSave}>OK</components.Button>
          </components.Tooltip>
        </components.ButtonGroup>
      </ModalFooter>
    </StyledModal>
  );
};

export default ContactEditModal;
