import React, { useState } from 'react';

export interface ILoadingContext {
  active: boolean;
  set: (loading: boolean) => void;
  finished: boolean;
  setFinished: (loading: boolean) => void;
}

export const LoadingContext = React.createContext<ILoadingContext>({} as any);

const LoadingWrapper: React.FC = (props) => {
  const [active, set] = useState(false);
  const [finished, setFinished] = useState(false);
  return (
    <LoadingContext.Provider value={{ active, set, finished, setFinished }}>
      {props.children}
    </LoadingContext.Provider>
  );
};

export default LoadingWrapper;
