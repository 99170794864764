import * as filestack from 'filestack-js';
import { PickerUploadDoneCallback } from 'filestack-js';
import { utils } from 'herald-fe-shared';

const MAX_FILE_SIZE_MB = 4;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1000 * 1000;

const client = filestack.init(process.env.REACT_APP_FILESTACK_API_KEY || '');
const defaultOptions = {
  accept: ['image/*'],
  fromSources: ['local_file_system', 'url', 'imagesearch', 'unsplash'],
  maxSize: 8 * 1024 * 1024, // in bytes
  imageMax: [2000, 2000] as any,
};

export const launchPicker = (
  onUploadDone: PickerUploadDoneCallback,
  options?: filestack.PickerOptions
) => {
  client.picker({ ...defaultOptions, ...options, onUploadDone }).open();
};

export const upload = async (data: Blob | string) => {
  if (typeof data === 'string') {
    const stored = await client.storeURL(data);
    return stored;
  }
  if (data instanceof Blob && data.size > MAX_FILE_SIZE_BYTES) {
    utils.toast.error(
      `Uploaded images cannot be greater than ${MAX_FILE_SIZE_MB}mb.`
    );
    return;
  }
  const file = await client.upload(data);
  return file;
};
