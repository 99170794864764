import React, { useContext } from 'react';
import { LoadingContext } from 'components/context/LoadingWrapper';

const LoadingWrapper: React.FC = () => {
  const { active, finished } = useContext(LoadingContext);
  return (
    <div
      className={`progress progress--${
        active || !finished ? 'loading' : 'stopped'
      }`}
    >
      <div className="indeterminate"></div>
    </div>
  );
};

export default LoadingWrapper;
