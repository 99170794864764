import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';
import styled from 'styled-components';
import {
  components,
  styles,
  colors,
  icons,
  utils,
  ISearchIssue,
  InternalContact,
  InternalContactTypeEnum,
  AddContactRequestTypeEnum,
} from 'herald-fe-shared';

import SuccessMessage from 'components/SaveSnippetMessage';

import { hooks, api, auth, environment, sendMessage, setShortcut } from 'lib';
import { SnippetContext, IError } from 'components/context/SnippetWrapper';
import { LoadingContext } from 'components/context/LoadingWrapper';
import { useIsExposed, useCanEdit } from 'lib/hooks';
import { upload } from 'lib/uploader';

const Styled = styled.div`
  position: fixed;
  z-index: 33;
  bottom: 0;
  left: 0;
  padding: 1rem 2rem;
  width: 100%;
  background: ${colors.WHITE()};
  border-top: ${styles.BORDER};
  box-shadow: ${styles.BOX_SHADOW};

  &.front {
    position: initial;
    box-shadow: none;
    background: none;
  }

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.WHITE()};
    z-index: 100;
    transition: 250ms opacity;
    opacity: 0;
    visibility: hidden;
  }
  .mask--open {
    opacity: 0.95;
    visibility: visible;
  }
  .mask__message {
    height: 100%;
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .mask__message__message {
    margin: 0 auto;
    margin-top: 2rem;
  }
  .mask__message svg {
    position: absolute;
    right: 2rem;
    top: 2rem;
    fill: ${(p: IStyledComponent) => p.theme.color()};
    opacity: 0.6;
    cursor: pointer;
    transition: 250ms all;
  }
  .mask__message svg:hover {
    opacity: 1;
  }
  .footer {
    display: flex;
    justify-content: space-between;
  }
  .footer__source {
    display: flex;
    align-items: center;
    color: ${colors.GRAY_3()};
    margin: 0;
  }
  .footer__source__app {
    margin-left: 6px;
    padding: 6px;
    background: ${colors.GRAY_2(0.3)};
    border-radius: 5px;
    display: flex;
  }
  .footer__source__app__logo {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .footer__source__unknown {
    margin-left: 0.5rem;
  }
`;

const SaveSnippet: React.FC = () => {
  const snippet = useContext(SnippetContext);
  const loading = useContext(LoadingContext);
  const isExposed = useIsExposed();
  const canEdit = useCanEdit();

  const [maskOn, setMaskOn] = useState(false);
  const [success, setSuccess] = useState<null | {
    issues: string[];
    quote: string;
    contact: string;
  }>(null);
  const [error, setError] = useState('');
  const [isValidating, setIsValidating] = useState(false);
  const [creating, setCreating] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  const { setError: setErrorObject, text, issues, contact } = snippet;

  hooks.useKeystroke(27, () => setMaskOn(false));

  const validate = useCallback(() => {
    // Error handling client-side.
    const err: IError = {
      quote: null,
      contact: null,
      issue: null,
    };
    if (!text) {
      err.quote = 'Please add a customer quote related to this feedback.';
    }
    if (text.length > utils.constants.MAX_QUOTE_LENGTH) {
      err.quote = `Cutomer quote cannot exceed ${utils.strings.formatNumber(
        utils.constants.MAX_QUOTE_LENGTH
      )} characters.`;
    }
    if (!issues || !issues[0]) {
      err.issue = 'Please select a topic associated with this feedback.';
    }
    if (!contact) {
      err.contact = 'Please select the contact that provided this feedback.';
    }
    if (contact?.isNew && !contact.name && !contact.email && !contact.phone) {
      err.contact =
        'Contacts must have at least one of name, email, or phone provided.';
    }
    setErrorObject(err);
    return err;
  }, [text, issues, contact, setErrorObject]);

  useEffect(() => {
    if (isValidating) {
      validate();
    }
  }, [isValidating, validate]);

  // Dismiss modal when the URL changes;
  const { url, originalUrl } = snippet;
  useEffect(() => {
    setMaskOn(false);
  }, [originalUrl]);

  const source = useMemo(() => utils.strings.getSource(url), [url]);

  const create = useCallback(async () => {
    if (creating || !canEdit) {
      return;
    }

    setCreating(true);
    setIsValidating(true);

    const err = validate();

    if (err.quote || err.contact || err.issue) {
      setCreating(false);
      return;
    }
    if (!issues[0] || !contact) {
      setCreating(false);
      return;
    }

    let contactId = contact?.id || '';
    let issueIds: string[] = [];
    let newContact: InternalContact | null = null;
    let attachmentURL: string | undefined;

    if (contact?.isNew) {
      const multiCustom = contact.custom
        ? Object.keys(contact.custom).map((attributeId) => ({
            attributeId,
            value: contact.custom[attributeId],
          }))
        : undefined;
      newContact = await api.create.contact({
        type:
          contact.type === InternalContactTypeEnum.Prospect
            ? AddContactRequestTypeEnum.Prospect
            : AddContactRequestTypeEnum.Customer,
        name: contact.name || undefined,
        phone: contact.phone || undefined,
        email: contact.email || undefined,
        multiCustom: multiCustom?.length ? multiCustom : undefined,
      });
      if (newContact) {
        contactId = newContact.id;
      }
    }

    const createOneQuote = async (issue: ISearchIssue) => {
      let issueId = issue?.id || '';

      if (issue?.isNew && issue?.description) {
        const newIssue = await api.create.issue({
          description: issue?.description,
          labelIds: issue?.labels?.map((l) => l.value),
          visible: isExposed ? (issue?.visible as any) : undefined,
          type: issue?.type ? (issue.type as any) : undefined,
        });
        if (newIssue) {
          issueId = newIssue.id;
        }
      }

      const res = await api.create.snippet({
        customerId: contactId,
        issueId,
        description: snippet.text,
        priority: snippet.priority,
        originalURL: snippet.url || undefined,
        note: snippet.note || undefined,
        searchQuery: snippet.issueInputValue,
        searchPosition: issue?.searchPosition,
        attachmentURL,
      });

      issueIds.push(issueId);

      return res;
    };

    try {
      loading.set(true);
      let res: any = null;

      if (snippet.attachments[0]) {
        try {
          const file = await upload(snippet.attachments[0]);
          attachmentURL = file?.url;
        } catch {
          setUploadError(true);
          throw new Error(`UPLOAD_ERROR`);
        }
      }
      await utils.strings.asyncForEach(
        issues.filter((i) => i),
        async (issue: ISearchIssue) => {
          res = await createOneQuote(issue);
        }
      );
      loading.set(false);
      setCreating(false);
      setIsValidating(false);
      setSuccess({ issues: issueIds, quote: res?.id, contact: contactId });
      setMaskOn(true);
      snippet.setText('');
      snippet.setIssues([null]);
      snippet.setNote('');
      snippet.setPriority(0);
      snippet.setIssueInputValue('');
      snippet.setAttachments([]);
      if (newContact) {
        snippet.setContact({ ...newContact, isNew: false });
      }
    } catch (e) {
      if (e.message !== 'UPLOAD_ERROR') {
        console.error(e);
        fetch(
          'https://hooks.slack.com/services/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',
          {
            method: 'POST',
            body: JSON.stringify({
              username: 'Herald Frontend Error',
              icon_url: 'https://app.heraldhq.com/favicon.png',
              text: `${
                auth.user?.displayName
              } encountered an error saving a quote: ${JSON.stringify(e)}`,
            }),
          }
        );
      }
      loading.set(false);
      setCreating(false);
      setSuccess(null);
      setError(utils.strings.getServerError(e, e.toString()));
      setMaskOn(true);
    } finally {
      sendMessage('EXTENSION_QUOTE_ADDED');
    }
  }, [
    setMaskOn,
    snippet,
    contact,
    issues,
    loading,
    validate,
    isExposed,
    creating,
    canEdit,
  ]);

  const saveButton = useMemo(
    () => (
      <components.Button
        loading={loading.active}
        onClick={create}
        large={environment.front}
        disabled={canEdit ? false : <>Only contributors can add feedback.</>}
      >
        Add Quote
      </components.Button>
    ),
    [loading, create, canEdit]
  );

  useEffect(() => {
    setShortcut('SAVE', create);
  }, [create]);

  return (
    <Styled className={environment.front ? 'front' : 'extension'}>
      <div className={`mask mask--${maskOn ? 'open' : 'closed'}`}>
        {success && (
          <div className="mask__message">
            <icons.Cross onClick={() => setMaskOn(false)} />
            <SuccessMessage
              issues={success.issues}
              quote={success.quote}
              contact={success.contact}
              setMaskOn={setMaskOn}
            />
          </div>
        )}
        <div className="mask__message">
          <icons.Cross onClick={() => setMaskOn(false)} />
          {uploadError ? (
            <div>
              <h3 style={{ marginBottom: 12 }}>
                Error uploading image attachment.
              </h3>
              <p>
                Please download the image and then upload it as an attachment to
                fix the issue.
              </p>
              <components.ButtonGroup
                style={{ marginTop: 24, justifyContent: 'center' }}
              >
                <components.Button
                  secondary={true}
                  onClick={() => setMaskOn(false)}
                >
                  Cancel
                </components.Button>
                <components.Button
                  secondary={true}
                  loading={loading.active}
                  onClick={() => {
                    snippet.setAttachments([]);
                    setUploadError(false);
                    setError('');
                    setMaskOn(false);
                  }}
                >
                  Remove Attachment
                </components.Button>
              </components.ButtonGroup>
            </div>
          ) : (
            <h3 className="mask__message__message text-red">{error}</h3>
          )}
        </div>
      </div>
      <div className="footer">
        {!environment.front && (
          <p className="footer__source">
            Feedback source is
            <span
              className="footer__source__app"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span className="footer__source__app__logo">
                <components.SourceLogo
                  source={source ? source : !url ? 'None' : 'Unknown'}
                />
              </span>
              <strong>{source ? source : !url ? 'None' : 'Unknown'}</strong>
            </span>
          </p>
        )}
        <components.ButtonGroup right={true}>
          <components.Tooltip
            disabled={!canEdit}
            message={
              <>
                <code>{environment.mac ? '⌘' : 'Ctrl'}</code>
                <code>Enter</code>
              </>
            }
          >
            {saveButton}
          </components.Tooltip>
        </components.ButtonGroup>
      </div>
    </Styled>
  );
};

export default SaveSnippet;
