import React, { useState, useCallback, useEffect, useContext } from 'react';

import { api, hooks, auth } from 'lib';
import { WorkspaceBasics } from 'herald-fe-shared';
import { LoadingContext } from 'components/context/LoadingWrapper';

export interface IWorkspaceContext {
  active: null | WorkspaceBasics;
  setActive: (workspace: WorkspaceBasics) => void;
}

export const WorkspaceContext = React.createContext<IWorkspaceContext>({
  active: null,
  setActive: () => null,
});

const WorkspaceWrapper: React.FC = (props) => {
  const { data: me } = hooks.useMe();
  const { set: setLoading } = useContext(LoadingContext);
  const [active, setActive] = useState<WorkspaceBasics | null>(null);

  const workspaces = me?.workspaces;

  const setActiveWorkspace = useCallback(async (workspace: WorkspaceBasics) => {
    await api.init.workspace(workspace);
    setActive(workspace);
  }, []);

  useEffect(() => {
    const workspace = api.activeWorkspace();
    if (workspace && workspace.slug) {
      setActive(workspace);
    } else if (!workspace && workspaces?.length > 0) {
      setActiveWorkspace(workspaces[0]);
    }
  }, [workspaces, setActiveWorkspace]);

  // These checks are in case the workspaces array changes while user
  // is logged in. We need to either change path or send user back to setup.
  // if they no longer have access to a workspace.
  useEffect(() => {
    if (active && workspaces) {
      const ws = workspaces.find((w) => w.id === active.id);
      if (ws) {
        return;
      } else if (workspaces[0]) {
        setActiveWorkspace(workspaces[0]);
      } else {
        setActive(null);
        api.clear();
      }
    }
  }, [workspaces, active, setActiveWorkspace]);

  useEffect(() => {
    auth.addCallback('clearWorkspaceState', false, () => {
      setTimeout(() => setLoading(false), 250);
      api.clear();
    });
  }, [setLoading]);

  return (
    <WorkspaceContext.Provider
      value={{
        active,
        setActive: setActiveWorkspace,
      }}
    >
      {props.children}
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceWrapper;
