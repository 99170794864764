import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Label, colors } from 'herald-fe-shared';

const Styled = styled.div`
  display: flex;
  align-items: center;
  margin-right: 6px;
  padding: 8px;
  border-radius: 4px;
  background: ${colors.GRAY_BUTTON()};
  width: fit-content;

  &:last-child {
    margin-right: 0;
  }

  .topic__product-area__dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .topic__product-area__name {
  }
  &:last-child .topic__product-area__separator {
    display: none;
  }
`;

export const ProductArea: React.FC<{
  label: Label;
}> = (props) => {
  const { label } = props;

  const color = useMemo(() => colors.hexToRgb(`#${label.color}`)(), [label]);

  if (!label) {
    return null;
  }
  return (
    <Styled className={`topic__product-area`}>
      <div
        className="topic__product-area__dot"
        style={{ backgroundColor: color }}
      />
      <p className="topic__product-area__name text-no-margin text-gray">
        {label.name}
      </p>
    </Styled>
  );
};

export default ProductArea;
