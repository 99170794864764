import React, {
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';
import {
  colors,
  components,
  utils,
  IssueSummaryVisibleEnum as Visible,
  UserRoleEnum as Role,
} from 'herald-fe-shared';

import Front from '@frontapp/plugin-sdk';

import Nav from 'components/Nav';
import SaveSnippet from 'components/SaveSnippet';
import IssueEditModal from 'components/IssueEditModal';
import ContactEditModal from 'components/ContactEditModal';
import AddQuoteWalkthrough from 'components/AddQuoteWalkthrough';
import ViewIssueButton from 'components/ViewIssueButton';

import { SnippetContext } from 'components/context/SnippetWrapper';
import { ParsedContactContext } from 'components/context/ParsedContactWrapper';
import { WorkspaceContext } from 'components/context/WorkspaceWrapper';
import { ModalContext } from 'components/context/ModalWrapper';

import { api, environment, hooks, getAppUniqueId } from 'lib';
import { useSuggestTopics, useIsExposed, useCanEdit } from 'lib/hooks';

const {
  QuoteInput,
  QuoteNote,
  QuoteAttachments,
  IssueSearch,
  ContactSearch,
} = components.forms;

const Styled = styled.div`
  padding-bottom: 4rem;
  .form-group--quote {
    border-top: 0;
    padding-top: 2rem;
  }
  .form-group--search .form-group__label {
    padding-left: 2rem;
  }
  .form-group--search .view-issue {
    margin-left: 0.75rem;
  }
  .form-group--note {
    padding-top: 24px;
  }
  .form-group {
    padding: 1rem 2rem;
  }
  .form-group--search {
    padding: 1rem 0;
    padding-bottom: 0;
  }
  &.container--front .form-group--search {
    padding: 2rem 0;
  }
  .form-group--search .form-group__content {
    padding-left: 2rem;
  }
`;

const CreateSnippet: React.FC = () => {
  const {
    text,
    setText,
    error,
    setError,
    note,
    setNote,
    priority,
    setPriority,
    contact,
    setContact,
    issues,
    setIssues,
    attachments,
    setAttachments,
    url,
    originalUrl,
    issueInputValue,
    setIssueInputValue,
  } = useContext(SnippetContext);
  const { active } = useContext(WorkspaceContext);
  const { contact: parsed, ready: parsedReady } = useContext(
    ParsedContactContext
  );

  const suggestTopics = useSuggestTopics();
  const isExposed = useIsExposed();
  const canEdit = useCanEdit();

  const { open } = useContext(ModalContext);
  const { data: me } = hooks.useMe();
  const { data: contactBackend } = hooks.useContact(contact?.id);
  const { data: users } = hooks.useMembers(me ? canEdit : true);

  const [quotesWithUrl, setQuotesWithUrl] = useState<number | null>(null);

  // Handler for quote input.
  const onChangeQuote = useCallback(
    (q: string) => {
      setText(q);
      if (error.quote && q.length > 0) {
        setError({ ...error, quote: null });
      }
    },
    [error, setError, setText]
  );

  // Auto-focus contact dropdown if we've already populated text but no contact
  const contactRef = useRef();
  const [contactFocused, setContactFocused] = useState(false);
  useEffect(() => {
    if (
      text &&
      parsedReady &&
      !contact &&
      contactRef &&
      contactRef.current &&
      (contactRef.current as any).focus &&
      !contactFocused
    ) {
      (contactRef.current as any).focus();
      setContactFocused(true);
    }
    // eslint-disable-next-line
  }, [contactRef, contactFocused, contact, parsedReady]);

  // When the contact changes, fetch updates for them from backend to overwrite algolia.
  useEffect(() => {
    if (contactBackend?.customer) {
      setContact({
        ...contact,
        ...(contactBackend as any).customer,
      });
    }
    // eslint-disable-next-line
  }, [contactBackend]);

  // Auto-focus issue dropdown if we've already populated text and contact
  const issueRef = useRef();
  const [issueFocused, setIssueFocused] = useState(false);

  useEffect(() => {
    if (
      text &&
      contact &&
      parsedReady &&
      !issues[0] &&
      issueRef &&
      issueRef.current &&
      (issueRef.current as any).focus &&
      !issueFocused
    ) {
      (issueRef.current as any).focus();
      setIssueFocused(true);
    } else if (parsedReady && contact) {
      setIssueFocused(true);
    }
    // eslint-disable-next-line
  }, [issueRef, issueFocused, text, contact, parsedReady, issues]);

  // Check to see if the current URL already has quote(s) associated with it.
  useEffect(() => {
    const updateTotal = async () => {
      if (url) {
        const r = await api.search?.quotes(getAppUniqueId(url), {
          typoTolerance: false,
          searchableAttributes: ['originalURL'],
          attributes: ['objectID'],
        });
        if (r) {
          setQuotesWithUrl(r.total);
        }
      }
    };

    if (url && api.ready && quotesWithUrl === null) {
      updateTotal();
    } else {
      api.addCallback('updateQuotesTotal', updateTotal);
    }
    // eslint-disable-next-line
  }, [url, active, quotesWithUrl]);

  // Handler for when user has created new contact and clicks edit button.
  const onClickEditContact = useCallback(() => {
    open({ content: ContactEditModal });
  }, [open]);

  // Handler for when user has created new issue and clicks edit button.
  const onClickEditIssue = useCallback(
    (id: number) => {
      open({ content: IssueEditModal as any, props: { id } });
    },
    [open]
  );

  // Handler for if user pastes files into quote issuePathMatcher
  const onPasteFiles = useCallback(
    (files: (string | File)[]) => {
      setAttachments(files);
    },
    [setAttachments]
  );

  // What app is the extension being opened from?
  const source = useMemo(() => utils.strings.getSource(originalUrl), [
    originalUrl,
  ]);

  const quotesWithUrlCount = useMemo(() => quotesWithUrl || 0, [quotesWithUrl]);

  const adminUsers = useMemo(
    () => users?.filter((u) => u.role === Role.Admin) || [],
    [users]
  );

  return (
    <Styled
      className={`container container--${
        environment.front ? 'front' : 'extension'
      }`}
    >
      <Nav />
      <AddQuoteWalkthrough />
      {!canEdit && (
        <components.ProTip
          style={{
            margin: '1rem 2rem',
            marginBottom: 0,
            backgroundColor: colors.YELLOW(0.1),
          }}
        >
          <strong>Heads up!</strong> Only "contributor" accounts may add
          feedback to Herald. To upgrade your account, please contact an admin:{' '}
          {adminUsers.map((u, i) => (
            <React.Fragment key={u.name}>
              <strong>{u.name}</strong> ({u.email})
              {i === adminUsers.length - 1 ? '' : ', '}
            </React.Fragment>
          ))}
          .
        </components.ProTip>
      )}
      <QuoteInput
        quote={text}
        error={error.quote}
        setQuote={onChangeQuote}
        priority={priority}
        onChangePriority={setPriority}
        onPasteFiles={onPasteFiles}
      >
        {quotesWithUrlCount > 0 && (
          <components.ProTip
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 0,
              background: colors.ORANGE(0.1),
              color: colors.GRAY_4(),
            }}
          >
            <span role="img" aria-label="reminder">
              🔔
            </span>
            <span style={{ marginLeft: '.5rem' }}>
              There {quotesWithUrlCount > 1 ? 'are' : 'is'} already{' '}
              {quotesWithUrlCount > 1 ? quotesWithUrlCount : 'one'} quote
              {quotesWithUrlCount > 1 ? 's' : ''} saved from this{' '}
              {environment.front ? 'conversation' : 'URL'}.{' '}
              {environment.front ? (
                <button
                  onClick={() =>
                    (Front as any).openUrl(
                      `${utils.strings.getAppOrigin()}/w/${
                        active?.slug
                      }/quotes?query="${escape(url)}"`
                    )
                  }
                >
                  See {quotesWithUrlCount > 1 ? 'them' : 'it'}.
                </button>
              ) : (
                <a
                  href={`${utils.strings.getAppOrigin()}/w/${
                    active?.slug
                  }/quotes?query="${escape(
                    getAppUniqueId(url)
                  )}"&option_typoTolerance=false`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See {quotesWithUrlCount > 1 ? 'them' : 'it'}.
                </a>
              )}
            </span>
          </components.ProTip>
        )}
        {!text && contact && me?.rookie && source && (
          <components.ProTip
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 0,
              background: colors.ORANGE(0.1),
              color: colors.GRAY_4(),
            }}
          >
            {environment.front ? (
              <>
                Copy a sentence or two from your inbox to the left and paste it
              </>
            ) : (
              <>
                You can just select text in {source} and open the Herald
                extension—we'll automatically carry it
              </>
            )}{' '}
            into the input above!
          </components.ProTip>
        )}
      </QuoteInput>
      <ContactSearch
        api={api}
        contact={contact}
        contactToSearch={parsed}
        contactToSearchReady={parsedReady}
        setContact={setContact}
        error={error.contact}
        workspace={active}
        onClickEdit={onClickEditContact}
        contactRef={contactRef}
        vertical={true}
        menuHeight={environment.front ? 300 : undefined}
      >
        {parsedReady && !parsed && !environment.front && (
          <components.ProTip>
            <span role="img" aria-label="reminder">
              🔔
            </span>
            <span style={{ marginLeft: '.5rem' }}>
              We automatically figure out the contact on supported apps like
              Gmail, Intercom, Front, and more.
            </span>
          </components.ProTip>
        )}
      </ContactSearch>
      <IssueSearch
        api={api}
        issues={issues}
        setIssues={setIssues}
        error={error.issue}
        workspace={active}
        onClickEdit={onClickEditIssue}
        issueRef={issueRef}
        vertical={true}
        suggestTopics={suggestTopics ? text : undefined}
        inputValue={issueInputValue}
        setInputValue={setIssueInputValue}
        viewIssueButton={ViewIssueButton}
        defaultVisibility={isExposed ? Visible.Visible : undefined}
        menuHeight={environment.front ? 300 : undefined}
      />
      <QuoteAttachments
        attachments={attachments}
        setAttachments={setAttachments}
        hideBrowse={environment.firefox && environment.extension}
      />
      <QuoteNote note={note} setNote={setNote} />
      <SaveSnippet />
    </Styled>
  );
};

export default CreateSnippet;
