import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {
  components,
  utils,
  colors,
  UserRoleEnum as Role,
} from 'herald-fe-shared';

import { environment, hooks } from 'lib';
import { WorkspaceContext } from 'components/context/WorkspaceWrapper';
import { useIsExposed, useIsViewer } from 'lib/hooks';

import ExternalLink from 'components/ExternalLink';

const Styled = styled.div`
  padding-top: 3rem;
  width: 100%;
  height: 100%;
  .mask__message__paragraph {
    margin: auto;
    margin-top: 1rem;
    max-width: 20rem;
  }
  .email__link {
    animation: fadeup 250ms;
    text-align: center;
    margin-top: 3rem;
  }
  .email__link__text {
    margin: auto;
    max-width: 24rem;
    margin-bottom: 1.5rem;
  }
  .email__link__body {
    margin: auto;
    border-radius: 4px;
    text-align: left;
    background: ${colors.GRAY_2(0.4)};
    width: fit-content;
    padding: 1rem;
    max-width: 30rem;
    overflow: hidden;
    cursor: pointer;
    white-space: nowrap;
    max-height: 12rem;
    overflow: hidden;
  }
  .email__link__buttons {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
`;

interface ISaveSnippetMessageProps {
  issues: string[];
  quote: string;
  contact: string;
  setMaskOn: (f: boolean) => void;
}

const getShortenedUrl = async (url: string) => {
  const r = await fetch(`https://api-ssl.bitly.com/v4/bitlinks`, {
    method: 'post',
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_BITLY_API_KEY}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ long_url: url }),
  });
  const data = await r.json();
  return data;
};

const SaveSnippetMessage: React.FC<ISaveSnippetMessageProps> = (props) => {
  const { issues, quote, contact: contactId, setMaskOn } = props;
  const { active } = useContext(WorkspaceContext);

  const isExposed = useIsExposed();
  const isViewer = useIsViewer();

  const { data: token } = hooks.useContactToken(contactId, 'FeedbackHistory');
  const { data: contact } = hooks.useContact(contactId);
  const { data: workspace } = hooks.useWorkspace();
  const { data: users } = hooks.useMembers(!isViewer);

  const [url, setUrl] = useState(``);
  const [shortening, setShortening] = useState(false);
  const [shortened, setShortened] = useState(false);

  const shortenLink = useCallback(async () => {
    if (shortened) {
      return;
    }
    setShortening(true);
    const r = await getShortenedUrl(url);
    setUrl(r.link);
    setShortened(true);
    setShortening(false);
  }, [url, shortened]);

  useEffect(() => {
    const originalUrl = `https://${
      workspace?.customSubdomain ||
      `${workspace?.slug}.${process.env.REACT_APP_HERALD_PORTAL_DOMAIN_SUFFIX}`
    }/${workspace?.slug}/history${token ? `?token=${token}` : ''}`;
    setUrl(originalUrl);
  }, [workspace, token]);

  const emailText = useMemo(
    () =>
      url
        ? `I just wanted to let you know we've tracked your feedback. Check out your feedback history here: ${url}`
        : null,
    [url]
  );

  const adminUsers = useMemo(
    () => users?.filter((u) => u.role === Role.Admin) || [],
    [users]
  );

  return (
    <Styled>
      <h1>
        <span role="img" aria-label="Congratulations!">
          🎉
        </span>
      </h1>
      <p className="mask__message__message">
        <strong>Congratulations!</strong> Your feedback was saved
        {issues.length > 1 && <> to {issues.length} topics</>}.
      </p>
      {isViewer && (
        <components.ProTip
          style={{
            margin: '1rem auto',
            backgroundColor: colors.YELLOW(0.1),
          }}
        >
          <strong>Heads up!</strong> Only "contributor" accounts may add
          feedback to Herald. Please contact an admin to upgrade your account:{' '}
          {adminUsers.map((u, i) => (
            <React.Fragment key={u.name}>
              <strong>{u.name}</strong> ({u.email})
              {i === adminUsers.length - 1 ? '' : ', '}
            </React.Fragment>
          ))}
          .
        </components.ProTip>
      )}
      <components.ButtonGroup
        style={{ margin: 'auto', marginTop: '1rem', justifyContent: 'center' }}
      >
        {/*<components.CopyButton
          text={`${utils.strings.getAppOrigin()}/w/${
            active ? active.slug : null
          }/issues/${issues[0]}`}
          successText="Link to topic copied."
        >
          <components.Button secondary={true}>
            Link to Topic
          </components.Button>
        </components.CopyButton>*/}
        <components.CopyButton
          text={`${utils.strings.getAppOrigin()}/w/${
            active ? active.slug : null
          }/issues/${issues[0]}/view/${quote}`}
          successText="Link to feedback copied."
        >
          <components.Button secondary={true}>Copy Link</components.Button>
        </components.CopyButton>
      </components.ButtonGroup>
      {contact && workspace && (
        <div className="email__link">
          {isExposed ? (
            <p className="email__link__text">
              Here's a personalized link for{' '}
              <strong>{contact.customer.name || 'your customer'}</strong>.
            </p>
          ) : (
            <div>
              <p style={{ textAlign: 'center' }}>
                <label
                  className="text-gray"
                  style={{
                    padding: 6,
                    marginRight: 6,
                    borderRadius: 4,
                    background: colors.GREEN(0.1),
                    color: colors.GREEN(),
                  }}
                >
                  New
                </label>{' '}
                Want to share feedback history with{' '}
                <strong>{contact.customer.name || 'your customer'}</strong>?{' '}
                <ExternalLink url={url}>Check out a preview</ExternalLink> and
                enable it from{' '}
                <ExternalLink
                  url={`${utils.strings.getAppOrigin()}/w/${
                    active ? active.slug : null
                  }/settings/public-portal`}
                >
                  your Public Portal settings
                </ExternalLink>
                .
              </p>
            </div>
          )}
          {isExposed && emailText && (
            <components.CopyButton
              text={url}
              successText={`Copied message to clipboard.`}
            >
              <p className="email__link__body">{url}</p>
            </components.CopyButton>
          )}
        </div>
      )}

      <components.ButtonGroup
        style={{ margin: 'auto', marginTop: '2rem', justifyContent: 'center' }}
      >
        {isExposed && emailText && (
          <React.Fragment>
            <components.Button
              secondary={true}
              onClick={shortenLink}
              loading={shortening}
            >
              Shorten
            </components.Button>
            <components.CopyButton
              text={url}
              successText={`Copied link to clipboard.`}
            >
              <components.Button>Copy</components.Button>
            </components.CopyButton>
            {/*<components.CopyButton
              text={emailText}
              successText={`Copied message to clipboard.`}
            >
              <components.Button>Copy Text</components.Button>
            </components.CopyButton>*/}
          </React.Fragment>
        )}
        {environment.front && (
          <components.Button onClick={() => setMaskOn(false)}>
            Close
          </components.Button>
        )}
      </components.ButtonGroup>
    </Styled>
  );
};

export default SaveSnippetMessage;
