import React, { useState, useContext, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { components, colors, icons, utils } from 'herald-fe-shared';
import { UserLoginRequestSourceEnum as LoginProvider } from 'herald-fe-shared';
import { auth, environment, api } from 'lib';
import { LoadingContext } from 'components/context/LoadingWrapper';
import ExternalLink from 'components/ExternalLink';

declare const window: any;
declare const browser: any;

type SetLoading = (loading: boolean) => void;
type UserExists = null | boolean;

export const TOKEN_ID = 'id_token';

const Styled = styled.div`
  width: 100%;
  height: 100vh;
  padding: 2rem;
  padding-bottom: 8rem;
  background: ${colors.GRAY_1()};
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .logo {
    animation: 2s fadeup;
    margin-bottom: 1rem;
  }
  button {
    margin-bottom: 8px;
  }
  button h5 {
    font-size: 1.25rem;
    display: flex;
    align-items: center;
  }
  button svg {
    margin-left: 8px;
    width: 3.5rem;
    height: 2rem;
  }
  .signup-logo path {
    fill: ${(p: IStyledComponent) => p.theme.color()};
  }
  button svg path {
    fill: ${colors.WHITE()};
  }
  .mask {
    animation: fadein 500ms;
    padding-top: 8rem;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: ${colors.WHITE(0.9)};
    text-align: center;
  }
  .mask__icon {
    width: 3rem;
    height: 3rem;
    margin: auto;
    fill: ${colors.GRAY_3()};
  }
  .mask__message {
    margin: auto;
    margin-top: 1rem;
    max-width: 20rem;
    text-align: center;
  }
  .mask__message a,
  .mask__message button {
    font-size: inherit !important;
    font-weight: inherit !important;
    margin: 0;
    padding: 0;
  }
`;

let windowRef: any = null;

const LOGIN_FUNCTIONS = {
  launchCheck: async (setLoading: SetLoading) => {
    if (environment.firefox && environment.extension) {
      const params = new URLSearchParams(window.location.hash.split('?').pop());
      const launch = params.get('launch');
      if (launch === 'microsoft') {
        setLoading(true);
        const userExists = await LOGIN_FUNCTIONS.microsoft.firefoxLaunch();
        return userExists;
      } else {
        const token = window.localStorage.getItem(TOKEN_ID);
        if (token) {
          setLoading(true);
          localStorage.removeItem(TOKEN_ID);
          const credential = auth.getCredential.google(token);
          await auth.signin.firebase(credential);
        }
      }
    }
    return null;
  },
  google: {
    default: async (setLoading: SetLoading) => {
      setLoading(true);
      if (environment.electron) {
        return await LOGIN_FUNCTIONS.google.electron();
      } else if (environment.firefox && environment.extension) {
        return await LOGIN_FUNCTIONS.google.firefox();
      }
      return await auth.signin.google();
    },
    electron: async () => {
      return await electronLogin(
        auth.oauthUrl('google', process.env.REACT_APP_PUBLIC_URL || ''),
        'Google Sign In',
        LOGIN_FUNCTIONS.google.final
      );
    },
    firefox: async () => {
      const redirectURL = browser.identity.getRedirectURL();
      const authURL = auth.oauthUrl('google', redirectURL);
      browser.runtime.sendMessage({
        type: 'INIT_LOGIN',
        data: {
          url: authURL,
          interactive: true,
        },
      });
    },
    final: async (token: string) => {
      const credential = auth.getCredential.google(token);
      auth.signin.firebase(credential);
    },
  },
  microsoft: {
    default: async (setLoading: SetLoading): Promise<UserExists> => {
      setLoading(true);
      if (environment.electron) {
        return await LOGIN_FUNCTIONS.microsoft.electron();
      } else if (environment.firefox && environment.extension) {
        await LOGIN_FUNCTIONS.microsoft.firefox();
        return null;
      }
      await auth.signin.microsoft();
      return null;
    },
    electron: async (): Promise<UserExists> => {
      return await electronLogin(
        auth.oauthUrl('microsoft', process.env.REACT_APP_PUBLIC_URL || ''),
        'Microsoft Sign In',
        async (code: string) => {
          return await LOGIN_FUNCTIONS.microsoft.final(
            code,
            process.env.REACT_APP_PUBLIC_URL || ''
          );
        }
      );
    },
    firefox: async () => {
      if (environment.firefox && environment.extension) {
        const url = await browser?.browserAction.getPopup({});
        browser?.tabs.create({ url: `${url}#/login?launch=microsoft` });
        window.close();
      }
    },
    firefoxLaunch: async () => {
      const redirectURL = browser.identity.getRedirectURL();
      const authURL = auth.oauthUrl('microsoft', redirectURL);
      const url: string = await browser.identity.launchWebAuthFlow({
        url: authURL,
        interactive: true,
      });
      const params = new URLSearchParams(url?.split('#').pop());
      const code = params?.get('code');
      if (code) {
        return await LOGIN_FUNCTIONS.microsoft.final(code, redirectURL);
      }
      return false;
    },
    final: async (code: string, redirectURL: string) => {
      const data = await api.meLogin({
        source: LoginProvider.Microsoft,
        code,
        redirectURL,
      });
      if (data?.token) {
        await auth.signin.firebaseToken(data.token);
        return true;
      }
      return false;
    },
  },
};

const electronLogin = async (
  url: string,
  name: string,
  cb: (token: string) => any
): Promise<UserExists> => {
  return new Promise((resolve) => {
    if (windowRef === null || windowRef.closed) {
      window.localStorage.removeItem(TOKEN_ID);
      const win = (windowRef = window.open(
        url,
        name,
        'width=600, height=700, top=100, left=100'
      ));
      let interval: any;
      const checkForToken = async () => {
        if (win.closed) {
          clearInterval(interval);
          return;
        }
        const token = window.localStorage.getItem(TOKEN_ID);
        if (token) {
          clearInterval(interval);
          window.localStorage.removeItem(TOKEN_ID);
          win.close();
          const res = await cb(token);
          resolve(res);
        }
      };
      interval = setInterval(checkForToken, 300);
    } else {
      windowRef.focus();
    }
  });
};

const MessageLoginToApp: React.FC<{ onClose: () => void }> = (props) => {
  const { onClose } = props;
  return (
    <div className="mask">
      <icons.ExclamationTriangle className="mask__icon" />
      <h4 className="mask__message text-gray">
        Please register your account{' '}
        <ExternalLink url={utils.strings.getAppOrigin()}>
          on the Herald app first
        </ExternalLink>{' '}
        to use the{' '}
        {environment.front && !environment.extension
          ? 'Front plugin'
          : environment.firefox
          ? 'Firefox Addon'
          : 'extension'}
        .
      </h4>
      <components.Button
        secondary={true}
        onClick={onClose}
        style={{ margin: 'auto', marginTop: 24 }}
      >
        Close
      </components.Button>
    </div>
  );
};

const MessageSuccess: React.FC<{ onClose: () => void }> = (props) => {
  const { onClose } = props;
  return (
    <div className="mask">
      <icons.CheckCircle
        className="mask__icon"
        style={{ fill: colors.GREEN() }}
      />
      <h4 className="mask__message text-gray">
        You're ready to go!{' '}
        {environment.front && !environment.extension
          ? 'Close this message to start using the Front plugin.'
          : `Close this window and launch the ${
              environment.front && !environment.extension
                ? 'Front plugin'
                : environment.firefox
                ? 'Firefox Addon'
                : 'extension'
            } from the Herald icon in the top right!`}
      </h4>
      {environment.front && !environment.extension && (
        <components.Button
          secondary={true}
          onClick={onClose}
          style={{ margin: 'auto', marginTop: 24 }}
        >
          Close
        </components.Button>
      )}
    </div>
  );
};

const Login: React.FC = () => {
  const loading = useContext(LoadingContext);

  const [isLoading, setIsLoading] = useState(false);
  const [invalidUser, setInvalidUser] = useState<boolean | null>(null);

  const setLoading = useCallback(
    (v: boolean) => {
      setIsLoading(v);
      loading.set(v);
    },
    [loading]
  );

  const handleUserExists = useCallback(
    (userExists: boolean | null) => {
      if (userExists === false) {
        setInvalidUser(true);
        setLoading(false);
      } else if (userExists && environment.extension) {
        window.close();
      }
    },
    [setLoading]
  );

  const environmentChecks = useCallback(async () => {
    handleUserExists(await LOGIN_FUNCTIONS.launchCheck(setLoading));
  }, [handleUserExists, setLoading]);

  const microsoftLogin = useCallback(async () => {
    handleUserExists(await LOGIN_FUNCTIONS.microsoft.default(setLoading));
  }, [handleUserExists, setLoading]);

  useEffect(() => {
    environmentChecks();
    // eslint-disable-next-line
  }, []);

  return (
    <Styled>
      {isLoading && <div className="mask" />}
      {invalidUser === true && (
        <MessageLoginToApp onClose={() => setInvalidUser(null)} />
      )}
      {invalidUser === false && (
        <MessageSuccess onClose={() => setInvalidUser(null)} />
      )}

      <div className="logo">
        <components.Logo width={200} />
      </div>
      <components.ButtonGroup
        style={{
          flexFlow: 'row wrap',
          justifyContent: 'center',
          marginTop: '3rem',
        }}
      >
        <components.Button
          large={true}
          onClick={() => LOGIN_FUNCTIONS.google.default(setLoading)}
        >
          Sign In with{' '}
          <icons.GoogleLogo
            className="google-logo"
            style={{
              marginLeft: 8,
              marginBottom: -2,
              height: 32,
              width: 48,
            }}
          />
        </components.Button>
        <components.Button large={true} onClick={microsoftLogin}>
          Sign In with Microsoft{' '}
          <icons.MicrosoftLogo
            className="microsoft-logo"
            style={{
              marginLeft: 8,
              marginTop: -1,
              height: 18,
              width: 18,
            }}
          />
        </components.Button>
      </components.ButtonGroup>
    </Styled>
  );
};

export default Login;
