import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

// Manually insert the font CSS for now.
const head = document.getElementsByTagName('head')[0];

const link1 = document.createElement('link');
link1.rel = 'preconnect';
link1.href = 'https://fonts.gstatic.com';

const link2 = document.createElement('link');
link1.rel = 'stylesheet';
link1.href =
  'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap';

head.appendChild(link1);
head.appendChild(link2);

ReactDOM.render(<App />, document.getElementById('root'));
