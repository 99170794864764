import React from 'react';
import styled from 'styled-components';
import { colors, utils } from 'herald-fe-shared';

import { auth } from 'lib';
import { ExternalButton, ExternalLink } from 'components/ExternalLink';

const Styled = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  padding-bottom: 8rem;
  background: ${colors.GRAY_1()};
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  .message {
    animation: fadeup 500ms;
    text-align: center;
  }
  .message h1 {
    margin-bottom: 4rem;
    font-size: 3rem;
  }
  .message p {
    margin: auto;
    max-width: 28rem;
    margin-bottom: 6rem;
  }
  .message p,
  .message button {
    font-size: 1.5rem;
  }
  .sign-out {
    font-size: 1rem;
    margin-top: 2rem;
  }
`;

const APP_URL = utils.strings.getAppOrigin();

const NoWorkspace: React.FC = () => {
  return (
    <Styled>
      <div className="message">
        <h1>Welcome to Herald</h1>
        <p className="large">
          To get started,{' '}
          <ExternalLink url={APP_URL}>head over to the Herald app</ExternalLink>{' '}
          and set up your team's workspace.
        </p>
        <ExternalButton
          buttonProps={{ large: true, style: { margin: 'auto' } }}
          anchorProps={{ style: { color: colors.WHITE() } }}
          url={APP_URL}
        >
          Open Herald
        </ExternalButton>
        <button className="sign-out" onClick={auth.signout}>
          Sign Out
        </button>
      </div>
    </Styled>
  );
};

export default NoWorkspace;
