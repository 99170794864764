import React, { useMemo } from 'react';
import Select from 'react-select';
import { colors } from 'herald-fe-shared';

type SelectOption = { value: string | number; label: string | JSX.Element };

interface ISimpleDropdownProps {
  options: Array<string | number | SelectOption>;
  value: string | number | SelectOption | null;
  onChange: (v: any) => void;
  placeholder?: string;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const SimpleDropdown: React.FC<ISimpleDropdownProps> = (props) => {
  const {
    options,
    value,
    className,
    onChange,
    placeholder,
    style,
    disabled,
  } = props;

  const newOptions = useMemo(
    () =>
      options.map((t) => {
        if (typeof t === 'string' || typeof t === 'number') {
          return {
            value: t,
            label: t,
          };
        }
        return t;
      }),
    [options]
  );

  const newValue = useMemo(() => {
    if (typeof value === 'string' || typeof value === 'number') {
      return {
        value,
        label: value,
      };
    }
    return value;
  }, [value]);

  return (
    <Select
      className={className}
      styles={{
        control: (base: any, state: any) => ({
          ...base,
          fontSize: '1.2rem',
          minHeight: 'none',
          height: '3rem',
          width: '100%',
          paddingLeft: '.3rem',
          border: `1px solid ${
            state.isFocused ? colors.PURPLE_LIGHT() : colors.GRAY_2()
          }`,
          background: colors.WHITE(),
          outline: 'none',
          ...style,
        }),
        placeholder: (base: any) => ({
          ...base,
          color: colors.GRAY_3(0.5),
        }),
        singleValue: (base: any) => ({
          ...base,
          color: colors.GRAY_3(),
        }),
        indicatorSeparator: () => ({ display: 'none' }),
      }}
      theme={(theme: any) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: colors.PURPLE_LIGHT(0.75),
          primary25: colors.GRAY_2(0.3),
        },
      })}
      tabSelectsValue={false}
      openMenuOnFocus={true}
      options={newOptions}
      placeholder={placeholder}
      value={newValue}
      onChange={(e: any) => {
        if (
          (options[0] && typeof options[0] === 'string') ||
          typeof options[0] === 'number'
        ) {
          onChange(e.value);
          return;
        }
        onChange(e);
      }}
      isDisabled={disabled}
    />
  );
};

export default SimpleDropdown;
