import React from 'react';
import styled from 'styled-components';
import { colors, styles } from 'herald-fe-shared';

interface IEmptyStateProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
  icon?: JSX.Element;
  small?: boolean;
}

const Styled = styled.div`
  margin-top: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadein 250ms;
  .container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 30rem;
    margin: auto;
  }
  svg {
    width: 100%;
    height: 20rem;
    margin-bottom: 1rem;
  }
  h1 {
    text-align: center;
    margin-bottom: 1rem;
  }
  p.empty-state__description,
  p.empty-state__description button {
    text-align: center;
    line-height: 1.75rem;
  }
  &.empty-state--small {
    width: calc(100% - 4rem);
    margin: 2rem;
    padding: 2rem;
    background: ${colors.GRAY_2(0.5)};
    border: ${styles.BORDER};
    border-radius: ${styles.BORDER_RADIUS};
  }
  &.empty-state--small .container {
    max-width: 20rem;
  }
  &.empty-state--small h1 {
    font-size: 1.2rem;
    margin-bottom: 0;
    color: ${colors.GRAY_3()};
  }
  &.empty-state--small p {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
`;

const EmptyState: React.FC<IEmptyStateProps> = (props) => {
  const { title, description, icon, small } = props;
  const classNames = [
    'empty-state',
    `empty-state--${small ? 'small' : 'large'}`,
  ];
  return (
    <Styled className={classNames.join(' ')}>
      <div className="container">
        {icon}
        {typeof title === 'string' ? (
          <h1 className="empty-state__title">{title}</h1>
        ) : (
          title
        )}
        {typeof description === 'string' ? (
          <p className="empty-state__description">{description}</p>
        ) : (
          description
        )}
      </div>
    </Styled>
  );
};

export default EmptyState;
