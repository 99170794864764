import React, { useState, useEffect } from 'react';
import Front from '@frontapp/plugin-sdk';

import { IParsedContact } from 'herald-fe-shared';
import domParsing, { PARSED_INFO_MESSAGE } from 'domParsing';
import { environment, auth } from 'lib';
import { ILocalState } from 'components/context/SnippetWrapper';

declare const chrome: any;

interface IParsedContactContext {
  contact?: IParsedContact;
  ready: boolean;
}

export const ParsedContactContext = React.createContext<IParsedContactContext>({
  ready: false,
});

const getCachedContact = (cacheKey?: string) => {
  if (cacheKey) {
    const cache = auth.store.get.state<ILocalState>();
    return cache?.[cacheKey]?.contact;
  }
  return;
};

const getParsedContact = (
  setContact: (c: IParsedContact | undefined) => void,
  setReady: (r: boolean) => void
) => {
  if (environment.extension && chrome.runtime && chrome.tabs) {
    chrome.tabs.executeScript({ code: `(${domParsing.toString()})()` });
    chrome.runtime.onMessage.addListener((request: any) => {
      if (request.type === PARSED_INFO_MESSAGE) {
        const parsed = request?.data || null;
        const cachedContact = getCachedContact(parsed?.url);
        setContact(
          parsed?.text || !cachedContact ? parsed?.contact : undefined
        );
        setReady(true);
      }
    });
  } else {
    setReady(true);
  }
};

const ParsedContactWrapper: React.FC = (props) => {
  const [ready, setReady] = useState(false);
  const [contact, setContact] = useState<undefined | IParsedContact>();

  useEffect(() => {
    getParsedContact(setContact, setReady);
    // Initi Front listeners
    const subscriber = Front.contextUpdates.subscribe((context) => {
      const { conversation: c } = context as any;
      const cachedContact = getCachedContact(
        `https://app.frontapp.com/open/${c?.id}`
      );
      switch (context.type) {
        case 'singleConversation':
          if (!cachedContact) {
            setContact({
              name: c?.recipient?.name,
              email: c?.recipient?.handle,
              searchId: c?.recipient?.handle,
            });
          }
          setReady(true);
          return;
        default:
          return;
      }
    });
    return () => {
      subscriber.unsubscribe();
    };
  }, []);

  return (
    <ParsedContactContext.Provider value={{ contact, ready }}>
      {props.children}
    </ParsedContactContext.Provider>
  );
};

export default ParsedContactWrapper;
