import React, { useState, useEffect, useContext } from 'react';
import { IFirebaseUser } from 'herald-fe-shared';
import { auth } from 'lib';
import { LoadingContext } from 'components/context/LoadingWrapper';

interface IAuthContext {
  status: boolean;
  user: null | IFirebaseUser;
}

export const AuthContext = React.createContext<IAuthContext>({
  status: false,
  user: null,
});

const AuthWrapper: React.FC = (props) => {
  const loading = useContext(LoadingContext);

  const [status, setStatus] = useState(auth.status);
  const [user, setUser] = useState<null | IFirebaseUser>(auth.user);

  useEffect(() => {
    auth.addCallback('authFinished', true, (user?: IFirebaseUser) => {
      if (user) {
        setUser(user);
      }
      loading.setFinished(true);
      setStatus(true);
    });
    auth.addCallback('authFinished', false, () => {
      loading.setFinished(true);
      setStatus(false);
    });
  }, [loading]);

  return (
    <AuthContext.Provider value={{ status, user }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthWrapper;
