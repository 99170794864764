import React from 'react';
import { components } from 'herald-fe-shared';
import { environment } from 'lib';
import Front from '@frontapp/plugin-sdk';

interface IExternalLinkProps {
  url: string;
  className?: string;
}

export const ExternalLink: React.FC<IExternalLinkProps> = (props) => {
  const { url, className } = props;
  return environment.electron ? (
    <button className={className} onClick={() => (Front as any).openUrl(url)}>
      {props.children}
    </button>
  ) : (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {props.children}
    </a>
  );
};

interface IExternalButtonProps {
  url: string;
  className?: string;
  buttonProps?: any;
  anchorProps?: any;
}

export const ExternalButton: React.FC<IExternalButtonProps> = (props) => {
  const { url, className, buttonProps, anchorProps } = props;
  return environment.electron ? (
    <components.Button
      {...buttonProps}
      className={className}
      onClick={() => (Front as any).openUrl(url)}
    >
      {props.children}
    </components.Button>
  ) : (
    <components.Button {...buttonProps} className={className}>
      <a {...anchorProps} href={url} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    </components.Button>
  );
};

export default ExternalLink;
