import { IParsedContact } from 'herald-fe-shared';

declare const chrome: any;
export const PARSED_INFO_MESSAGE = 'PARSED_INFO_MESSAGE';

export default () => {
  const PHONE_REGEX = new RegExp(
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
  );
  const EMAIL_REGEX = new RegExp(
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const APPS = {
    'app.intercom.io': 'Intercom',
    'app.intercom.com': 'Intercom',
    'mail.google.com': 'GMail',
    'app.frontapp.com': 'Front',
    'login.casengo.com': 'Casengo',
    'my.openphone.co': 'OpenPhone',
    'app.crisp.chat': 'Crisp',
    'discordapp.com': 'Discord',
    'discord.com': 'Discord',
    localhost: 'Herald',
    'app.heraldhq.com': 'Herald',
    'heraldhq-app-staging.netlify.app': 'Herald',
    'inbox.messagebird.com': 'MessageBird',
    'mail.superhuman.com': 'Superhuman',
    '.zendesk.com': 'Zendesk',
  };

  const formatPhone = (s: string) =>
    s
      .trim()
      .replace(/ /g, '')
      .replace(/-/g, '')
      .replace('(', '')
      .replace(')', '');

  const getDiscordContact = (): IParsedContact | undefined => {
    const parseDiscordMessage = (
      el: any,
      id: string,
      count: number
    ): IParsedContact | undefined => {
      if (count > 4 || !el) {
        return undefined;
      }
      const username = el.querySelector('[class^="username-"]')?.textContent;
      if (username) {
        return {
          url: id
            ? `https://discordapp.com${window.location.pathname}/${id}`
            : undefined,
          name: username,
          searchId: username,
        };
      }
      return parseDiscordMessage(el.previousSibling, id, count + 1);
    };
    const message = (document.getSelection() as any)?.baseNode?.parentElement?.closest(
      '[class^="message-"]'
    );
    if (message) {
      return parseDiscordMessage(message, message.id, 0);
    }
    return;
  };

  const getGmailContact = (): IParsedContact | undefined => {
    // To get a more accurately parsed contact, we will first try to start at the DOM node
    // of the highlighted text, and move up the tree until we find the entire message's
    // container DOM node, and then use the email in that container as the contact.
    const goUpDomTree = (el?: Element): Element | null => {
      if (!el) {
        return null;
      }
      if ((el as any).dataset?.messageId) {
        return el;
      }
      if (el.parentElement) {
        return goUpDomTree(el.parentElement);
      }
      return null;
    };
    let messageSpan = goUpDomTree((document.getSelection() as any)?.baseNode);

    // If starting with highlighted text doens't work, or if there is no selected text, then
    // we will just find the first email in the DOM and use that as the contact.
    if (!messageSpan) {
      messageSpan = document.querySelector('[data-message-id]');
    }
    const authorSpan = messageSpan && messageSpan.querySelector('[email]');
    if (authorSpan) {
      const emailAttribute = authorSpan.getAttribute('email');
      const email = emailAttribute?.trim().toLowerCase() || undefined;
      const name = authorSpan.textContent?.trim();
      if (email || name) {
        return {
          searchId: email || name, // TODO: believe searchId should be calculated outside of the parsing code here
          email,
          name,
        };
      }
    }
    return;
  };

  const getIntercomContact = (): IParsedContact | undefined => {
    let intercomId = null;
    let name = null;
    document.querySelectorAll('[href^="/a/apps/"]').forEach((a) => {
      const href = a.getAttribute('href');
      if (
        href &&
        href?.indexOf('/users/') > -1 &&
        href?.indexOf('/segments') === -1
      ) {
        intercomId = href.split('/users/')[1];
        const text = a.textContent?.trim() || '';
        if (text.length > 2) {
          name = text;
        }
      }
    });
    const emailSpan: any = document.querySelector(
      '[data-attribute="Email"] [data-value]'
    );
    const email = emailSpan && emailSpan.dataset.value.trim().toLowerCase();
    if (!name) {
      const nameSpan = document.querySelector('[data-test-section-user-name]');
      name = nameSpan && nameSpan.textContent?.trim();
    }
    return {
      searchId: email || intercomId || undefined,
      email,
      name: name || undefined,
      vendorId: intercomId || undefined,
    };
  };

  let zendeskTried = false;
  const getZendeskContact = (): Promise<IParsedContact | undefined> => {
    const customerContext = document.querySelectorAll('.customer_context');
    if (customerContext.length) {
      const container = document.querySelector('.fr-focus');
      const nameSpan = container?.querySelector(
        '.customer_context [data-test-id="customercontext-userinfo-user"] span'
      );
      const emailSpan = container?.querySelector(
        '.customer_context [data-test-id="customercontext-userinfo-email-0"] a'
      );
      const phoneSpan = container?.querySelector(
        '.customer_context [data-test-id="customercontext-userinfo-phonenumber-0"] a'
      );
      const name = nameSpan?.textContent || undefined;
      const email = emailSpan?.textContent || undefined;
      const phone = phoneSpan?.textContent || undefined;
      return Promise.resolve({
        searchId: email || name,
        email,
        name,
        phone: phone ? formatPhone(phone) : undefined,
      });
    }
    const userTab = document.querySelector(
      '[data-test-id="customer-context-tab-customer"]'
    );
    if (userTab && !zendeskTried) {
      zendeskTried = true;
      (userTab as any).click();
      return new Promise((resolve) => {
        setTimeout(() => resolve(getZendeskContact()), 2000);
      });
    }
    const selection = window.getSelection();
    if (selection) {
      let name;
      document
        .querySelectorAll('[data-test-id="omni-log-comment-item"]')
        .forEach((n) => {
          if (selection.anchorNode && n.contains(selection.anchorNode)) {
            const linkSpan = n.querySelector(
              '[data-test-id="omni-log-item-sender"] a[href^="users"]'
            );
            name = linkSpan?.textContent;
          }
        });
      return Promise.resolve({
        name,
        searchId: name,
      });
    }
    return Promise.resolve(undefined);
  };

  const getFrontContact = (): IParsedContact | undefined => {
    const recipientSpan = document.querySelector(
      '[class^=messageViewerSender__StyledRecipientSpan]'
    );
    const recipientEmailOrPhoneSpan = recipientSpan?.querySelector(
      '[class^=messageViewerRecipient__StyledHandleSpan]'
    );
    const recipientNameSpan = recipientSpan?.querySelector(
      '[class^=messageViewerRecipient__StyledNameDiv]'
    );
    const emailOrPhoneSpan = document.querySelector(
      '[class^=messageViewerSender__StyledHandleSpan]'
    );
    const nameSpan = document.querySelector(
      '[class^=messageViewerSender__StyledNameSpan]'
    );

    let emailOrPhone = emailOrPhoneSpan?.textContent?.trim();
    let name = nameSpan?.textContent?.trim();

    if (recipientNameSpan && recipientEmailOrPhoneSpan) {
      name = recipientNameSpan.textContent?.trim();
      emailOrPhone = recipientEmailOrPhoneSpan.textContent?.trim();
    } else if (recipientSpan) {
      emailOrPhone = recipientSpan.textContent?.trim();
      name = '';
    }

    if (emailOrPhone || name) {
      let phone = '';
      let email = '';
      // When emails are displayed in Frontm they always start with '<' character.
      if (emailOrPhone && emailOrPhone.startsWith('<')) {
        const emailTokens = emailOrPhone.split(/[<>]/);
        if (emailTokens.length > 1) {
          email = emailTokens[1];
        }
      }
      // When phone numbers are displayed in Front they sometimes start with '(' character.
      if (emailOrPhone) {
        if (emailOrPhone.startsWith('(')) {
          const phoneTokens = emailOrPhone.split(/[()]/);
          if (phoneTokens.length > 1) {
            phone = formatPhone(phoneTokens[1]);
          }
        } else if (emailOrPhone.startsWith('+')) {
          phone = formatPhone(emailOrPhone);
        }
      }
      if (email || phone || name) {
        return {
          searchId: email || phone || name,
          email,
          phone,
          name,
        };
      }
    }
    return;
  };

  const getCasengoContact = (): IParsedContact | undefined => {
    const phoneSpan = document.querySelector(
      '.contact-field-input[name="phone"]'
    );
    const nameSpan = document.querySelector('[name="name"]');
    const nameValue = nameSpan?.textContent?.trim();
    const name = nameValue
      ? nameValue.startsWith('+')
        ? undefined
        : nameValue
      : undefined;
    if (phoneSpan || name) {
      const phoneTokens = phoneSpan?.textContent?.split('+');
      const phone =
        phoneTokens && phoneTokens.length > 0
          ? formatPhone(phoneTokens[1].trim())
          : undefined;
      if (phone || name) {
        return {
          searchId: phone || name,
          phone,
          name,
        };
      }
    }
    return;
  };

  const getCrispContact = (): IParsedContact | undefined => {
    const emailSpan = document.querySelector('.c-conversation-profile__email');
    const nameSpan = document.querySelector(
      '.c-conversation-profile__nickname'
    );
    if (emailSpan || nameSpan) {
      const emailRaw = emailSpan?.textContent?.trim();
      const name = nameSpan?.textContent?.trim();
      const email =
        emailRaw !== 'set email' ? emailRaw?.toLowerCase() : undefined;
      return {
        name,
        email,
        searchId: email || name,
      };
    }
    return;
  };

  const getOpenPhoneContact = (): IParsedContact | undefined => {
    const titleDiv = document.querySelector('#conversation-call')
      ?.previousElementSibling;
    const spans = document.getElementsByTagName('span');
    let name = '';
    let phone = '';
    let email = '';
    if (titleDiv) {
      const primary = titleDiv.querySelector('p')?.textContent;
      const secondary = titleDiv.querySelector('span')?.textContent;
      if (primary?.match(PHONE_REGEX)) {
        phone = primary;
      } else {
        name = primary || '';
      }
      if (secondary?.match(PHONE_REGEX)) {
        phone = secondary;
      }
      phone = formatPhone(phone);
    }
    Array.from(spans).forEach((s) => {
      if (s?.textContent === 'Company') {
        const text = s.parentElement?.nextElementSibling?.textContent;
        if (text !== 'Empty' && text?.match(EMAIL_REGEX)) {
          email = text.toLowerCase();
        }
      }
    });
    if (name || phone) {
      return {
        name,
        phone,
        searchId: email || phone || name,
      };
    }
    return;
  };

  const getMessageBirdContact = (): IParsedContact | undefined => {
    let name = '';
    let phone = '';
    let email = '';
    const title = document.querySelector('main span')?.textContent;
    if (title && title.match(PHONE_REGEX)) {
      phone = title;
    } else if (title) {
      name = title;
    }
    document
      .querySelectorAll('[data-testid="contact-section"] span')
      .forEach((s) => {
        const text = s.textContent;
        if (text?.match(PHONE_REGEX)) {
          phone = text;
        } else if (text?.match(EMAIL_REGEX)) {
          email = text;
        }
      });
    return {
      name,
      phone,
      email,
      searchId: email || phone || name,
    };
  };

  const getSuperhumanContact = (): IParsedContact | undefined => {
    const name =
      document.querySelector('.ContactPane-name')?.textContent || undefined;
    const email =
      document.querySelector('.ContactPane-compose-to-link')?.textContent ||
      undefined;
    let url = document.location.href;
    if (url.split('thread/')[1]) {
      url = `https://${window.location.hostname}/thread/${
        url.split('thread/')[1]
      }`;
    }
    return {
      name,
      email,
      url,
      searchId: email || name,
    };
  };

  const getHeraldContact = (): IParsedContact | undefined => {
    const { pathname, hash } = document.location;
    if (pathname.startsWith('/setup') && hash === '#addQuote') {
      return {
        name: 'Jason Herald',
        email: 'jason@heraldhq.com',
      };
    }
    return;
  };

  const getSelectedImages = () => {
    const sel = window.getSelection();
    return Array.from(document.querySelectorAll('img'))
      .filter((node) => sel?.containsNode(node))
      .map((node) => node.getAttribute('src'));
  };

  const getContact = (app?: string): Promise<IParsedContact | undefined> => {
    switch (app) {
      case 'Intercom':
        return Promise.resolve(getIntercomContact());
      case 'GMail':
        return Promise.resolve(getGmailContact());
      case 'Front':
        return Promise.resolve(getFrontContact());
      case 'Casengo':
        return Promise.resolve(getCasengoContact());
      case 'OpenPhone':
        return Promise.resolve(getOpenPhoneContact());
      case 'Crisp':
        return Promise.resolve(getCrispContact());
      case 'Discord':
        return Promise.resolve(getDiscordContact());
      case 'MessageBird':
        return Promise.resolve(getMessageBirdContact());
      case 'Superhuman':
        return Promise.resolve(getSuperhumanContact());
      case 'Zendesk':
        return getZendeskContact();
      case 'Herald':
        return Promise.resolve(getHeraldContact());
      default:
        return Promise.resolve(undefined);
    }
  };

  const getApp = () => {
    let app = undefined;
    const hostname = document.location.hostname;
    Object.keys(APPS).forEach((appHostname) => {
      if (hostname.indexOf(appHostname) > -1) {
        app = APPS[appHostname];
      }
    });
    return app;
  };

  const app = getApp();

  getContact(app).then((contact) => {
    if (typeof chrome !== 'undefined') {
      chrome.runtime.sendMessage({
        type: 'PARSED_INFO_MESSAGE',
        data: {
          text: document.getSelection()?.toString(),
          url: contact?.url || document.location.href,
          app,
          userId: contact?.searchId || null,
          vendor: app,
          contact,
          images: getSelectedImages(),
        },
      });
    }
  });
};
