import React, { useContext, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Link, useLocation, matchPath } from 'react-router-dom';
import Front from '@frontapp/plugin-sdk';

import {
  components,
  colors,
  icons,
  utils,
  styles,
  WorkspaceBasics,
} from 'herald-fe-shared';

import { ROUTES } from 'App';
import { AuthContext } from 'components/context/AuthWrapper';
import { WorkspaceContext } from 'components/context/WorkspaceWrapper';
import { SnippetContext } from 'components/context/SnippetWrapper';
import { auth, hooks, environment } from 'lib';

const Styled = styled.header`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 31;

  .shutdown {
    width: 100%;
    padding: 0.5rem;
    background: ${colors.BLUE_DARK()};
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin: 0;
      color: white;
    }
    a {
      color: white;
      text-decoration: underline;
    }
    svg {
      fill: white;
      margin-left: 5px;
    }
  }

  .nav {
    height: 4rem;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${colors.GRAY_1()};
    border-bottom: ${styles.BORDER};
  }
  .nav__user__summary {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 11;
  }
  .nav__user__name {
    margin-left: 1rem;
  }
  .nav__menu {
    border: ${styles.BORDER};
    position: absolute;
    top: calc(100% + 1rem);
    left: -1rem;
    width: fit-content;
    max-height: calc(100vh - 8rem);
    overflow: auto;
    padding: 1rem;
    border-radius: ${styles.BORDER_RADIUS};
    background: ${colors.WHITE()};
    box-shadow: ${styles.BOX_SHADOW};
    visibility: hidden;
    opacity: 0;
    transform: translateY(5px);
    transition: 250ms all;
    &:before {
      position: absolute;
      top: -0.6rem;
      left: calc(1rem + 5px);
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 0.6rem solid transparent;
      border-right: 0.6rem solid transparent;

      border-bottom: 0.6rem solid ${colors.WHITE()};
    }
  }
  .nav__menu--ws {
    padding: 0.5rem;
    left: initial;
    right: -1rem;
    top: calc(100% + 0.5rem);
    &:before {
      left: initial;
      right: calc(1rem + 5px);
    }
  }
  .nav__menu--open {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
  }
  .nav__menu p {
    font-size: 0.8rem;
    margin: 0;
  }
  .nav__menu h5,
  .nav__menu p {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .nav__menu__signout {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  .nav__menu__signout svg {
    fill: ${(p: IStyledComponent) => p.theme.color()};
    margin-right: 0.25rem;
  }
  .nav__menu__button {
    padding: 0.5rem;
    transition: 250ms all;
    width: 100%;
  }
  .nav__menu__button:hover {
    background: ${(p: IStyledComponent) => p.theme.color(0.1)};
  }
  .nav__menu__button h5 {
    color: ${(p: IStyledComponent) => p.theme.color()};
    text-align: left;
  }
  .nav__workspace {
    position: relative;
    display: flex;
    align-items: center;
    height: 2.5rem;
    z-index: 11;
  }
  .nav__workspace__webapp {
    height: 100%;
    width: 3rem;
    background: ${colors.GRAY_2()};
    margin-right: 1px;
    border-radius: 3px 0 0 3px;
  }
  .nav__workspace__webapp__link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav__workspace > .tooltip,
  .nav__workspace > .tooltip > .tooltip-trigger {
    height: 100%;
  }
  .nav__workspace__webapp svg {
    transition: 250ms all;
    fill: ${colors.GRAY_4(0.5)};
    width: 1rem;
    height: 1rem;
  }
  .nav__workspace__webapp:hover svg {
    fill: ${colors.GRAY_4()};
  }
  .nav__workspace__button {
    padding: 0.5rem 0.75rem;
    background: ${colors.GRAY_2()};
    display: flex;
    align-items: center;
    border-radius: 0 3px 3px 0;
    height: 100%;
  }
  .nav__workspace__button svg {
    fill: ${colors.GRAY_3()};
    margin-left: 0.5rem;
    transform: rotate(90deg);
  }
  .nav__workspace__name {
    color: ${colors.GRAY_3()};
  }
  .nav__tabs {
    flex: 1 1 auto;
    margin-left: 2rem;
    height: 4rem;
    position: relative;
    z-index: 11;
    display: flex;
    border-left: ${styles.BORDER};
  }
  .nav__tabs__tab {
    height: 4rem;
    width: 4rem;
    border-right: ${styles.BORDER};
    border-bottom: 2px solid ${(p: IStyledComponent) => p.theme.color(0)};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 250ms all;
  }
  .nav__tabs__tab svg {
    fill: ${colors.GRAY_3()};
  }
  .nav__tabs__tab:hover {
    background: ${colors.GRAY_2(0.4)};
  }
  .nav__tabs__tab--active {
    background: ${(p: IStyledComponent) => p.theme.color(0.1)} !important;
    border-bottom: 2px solid ${(p: IStyledComponent) => p.theme.color(1)};
  }
  .nav__tabs__tab--active svg {
    fill: ${(p: IStyledComponent) => p.theme.color()};
  }
`;

const KEYS_DISPLAY_NAME = {
  meta: environment.mac ? '⌘' : 'Ctrl',
  alt: environment.mac ? 'Option' : 'Alt',
};

const Nav: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { active, setActive } = useContext(WorkspaceContext);
  const { setContact, setIssues, setNote } = useContext(SnippetContext);
  const { data: me } = hooks.useMe();
  const { pathname } = useLocation();

  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [wsMenuOpen, setWsMenuOpen] = useState(false);

  const workspaces = me?.workspaces || [];
  const userMenuRef = hooks.useClickOutside(() => setUserMenuOpen(false));
  const wsMenuRef = hooks.useClickOutside(() => setWsMenuOpen(false));

  const activePage = useMemo(
    () => ROUTES.find((r) => matchPath(pathname, { path: r.pathname })),
    [pathname]
  );

  const changeWs = useCallback(
    (ws: WorkspaceBasics) => {
      setActive(ws);
      setContact(null);
      setIssues([null]);
      setNote('');
      setWsMenuOpen(false);
    },
    [setActive, setContact, setIssues, setNote]
  );

  return (
    <Styled>
      <div className="shutdown">
        <p>
          Herald is shutting down on October 31, 2021.{' '}
          <a
            href="https://www.heraldhq.com/sunset"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </p>
        <icons.Arrow />
      </div>
      <div className="nav">
        <div className="nav__user">
          {user && (
            <div className="nav__user__summary">
              <div
                className="nav__user__summary__avatar"
                onClick={() => setUserMenuOpen(!userMenuOpen)}
              >
                <components.Avatar r={24} image={me?.user?.photoURL || ''} />
              </div>
              <div
                ref={userMenuRef}
                className={`nav__menu nav__menu--${
                  userMenuOpen ? 'open' : 'closed'
                }`}
              >
                <h4>{me?.user?.name || me?.user?.email}</h4>
                <p className="text-small text-no-margin">{me?.user?.email}</p>
                <button className="nav__menu__signout" onClick={auth.signout}>
                  <icons.SignOut /> Sign Out
                </button>
              </div>
            </div>
          )}
        </div>

        {!environment.front && (
          <div className="nav__tabs">
            {ROUTES.filter((r) => r.showInNav).map((r) => (
              <Link to={r.pathname} key={r.pathname}>
                <components.Tooltip
                  message={
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {r.displayName} •{' '}
                      {r.shortcut?.map((s) => (
                        <code key={s}>
                          {KEYS_DISPLAY_NAME[s] || utils.strings.capitalize(s)}
                        </code>
                      ))}
                    </span>
                  }
                >
                  <div
                    className={`nav__tabs__tab nav__tabs__tab--${
                      r.pathname === activePage?.pathname
                        ? 'active'
                        : 'inactive'
                    }`}
                  >
                    {r.icon && <r.icon />}
                  </div>
                </components.Tooltip>
              </Link>
            ))}
          </div>
        )}

        {workspaces && active && (
          <div className="nav__workspace">
            <components.Tooltip
              message={
                <>
                  Click to open the <strong>{active.displayName}</strong>{' '}
                  workspace in the Herald app.
                </>
              }
              width={120}
            >
              <div className="nav__workspace__webapp">
                {environment.electron ? (
                  <button
                    className="nav__workspace__webapp__link"
                    onClick={() =>
                      (Front as any).openUrl(
                        `${utils.strings.getAppOrigin()}/w/${active.slug}`
                      )
                    }
                  >
                    <icons.ExternalLink />
                  </button>
                ) : (
                  <a
                    href={`${utils.strings.getAppOrigin()}/w/${active.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav__workspace__webapp__link"
                  >
                    <icons.ExternalLink />
                  </a>
                )}
              </div>
            </components.Tooltip>
            <button
              className="nav__workspace__button"
              disabled={workspaces.length === 1}
              onClick={() => setWsMenuOpen(!wsMenuOpen)}
            >
              <h4 className="nav__workspace__name">{active.displayName}</h4>
              {workspaces.length > 1 && <icons.AngleRight />}
            </button>
            <div
              ref={wsMenuRef}
              className={`nav__menu nav__menu--ws nav__menu--${
                wsMenuOpen ? 'open' : 'closed'
              }`}
            >
              {workspaces.map((ws) => (
                <button
                  className="nav__menu__button"
                  key={ws.slug}
                  onClick={() => changeWs(ws)}
                >
                  <h5 className="text-gray">{ws.displayName}</h5>
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </Styled>
  );
};

export default Nav;
