import React, {
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';
import {
  components,
  styles,
  IssueSummaryVisibleEnum,
  SingleIssueSummaryTypeEnum as Type,
  utils,
  LabelTypeEnum as LabelType,
} from 'herald-fe-shared';

import ProductArea from 'components/ProductArea';

import { SnippetContext } from 'components/context/SnippetWrapper';
import { ModalContext, ModalFooter } from 'components/context/ModalWrapper';
import { WorkspaceContext } from 'components/context/WorkspaceWrapper';

import { hooks, environment, setShortcut, removeShortcut } from 'lib';
import { useIsExposed } from 'lib/hooks';

const { TOPIC_TYPE_METADATA } = utils.constants;
const { IssueDescription, IssueLabels, IssueVisibility } = components.forms;

const StyledModal = styled.div`
  .header {
    padding: 2rem;
    border-bottom: ${styles.BORDER};
  }
  .content {
    padding: 0 2rem;
  }
  .form-group {
    padding: 0 2rem;
    margin-top: 2rem;
  }
  .input__container {
    height: 3rem;
  }
  input {
    font-size: 1.2rem;
  }
`;

const IssueEditModal: React.FC<{ id: number }> = (props) => {
  const { id } = props;
  const { issues, setIssues } = useContext(SnippetContext);
  const { data: labels } = hooks.useLabels();
  const { close } = useContext(ModalContext);
  const { active } = useContext(WorkspaceContext);

  const issue = issues && issues[id];
  const [description, setDescription] = useState(issue?.description || '');
  const [noneLabels, setNoneLabels] = useState(
    issue?.labels?.filter((l) => l.data?.type === LabelType.None) || []
  );
  const [areaLabels, setAreaLabels] = useState(
    issue?.labels?.filter((l) => l.data?.type === LabelType.Area) || []
  );
  const [visible, setVisible] = useState(
    issue?.visible || IssueSummaryVisibleEnum.Visible
  );
  const [type, setType] = useState(issue?.type || Type.Feature);

  const changeAreaLabels = useCallback(
    (label: any) => setAreaLabels(label ? [label] : []),
    []
  );

  const nameRef = useRef(null);
  const isExposed = useIsExposed();

  const labelOptions = useMemo(
    () =>
      labels
        ? labels.map((label) => ({
            value: label.id,
            label:
              label.type === LabelType.Area ? (
                <ProductArea label={label} />
              ) : (
                <components.LabelPill label={label} />
              ),
            data: label,
          }))
        : [],
    [labels]
  );

  const onSave = useCallback(() => {
    const newIssues = issues ? [...issues] : [];
    newIssues[id] = {
      isNew: true,
      description,
      labels: [...noneLabels, ...areaLabels].map((l) => ({
        ...l,
        label: l.data,
      })) as any,
      visible,
      type,
    };
    setIssues(newIssues);
    close();
  }, [
    close,
    description,
    noneLabels,
    areaLabels,
    visible,
    setIssues,
    id,
    issues,
    type,
  ]);

  useEffect(() => {
    if (nameRef && nameRef.current) {
      setTimeout(() => (nameRef.current as any).focus(), 250);
    }
  }, [nameRef]);

  useEffect(() => {
    setShortcut('SAVE_MODAL', onSave);
    return () => removeShortcut('SAVE_MODAL');
  }, [onSave]);

  return (
    <StyledModal>
      <div className="header">
        <h4>Edit Issue</h4>
      </div>
      <IssueDescription
        description={description}
        setDescription={setDescription}
        inputProps={{ ref: nameRef }}
      />
      <components.FormGroup label="Type" className="form-group--type">
        <components.Toggle
          value={type}
          onChange={(type: any) => setType(type)}
          options={Array.from(TOPIC_TYPE_METADATA, (t) => t[0])}
        />
      </components.FormGroup>
      <IssueLabels
        options={labelOptions.filter((l) => l.data.type === LabelType.Area)}
        labels={(areaLabels || []).map((l) => ({
          ...l,
          label:
            l.data.type === LabelType.Area ? (
              <ProductArea label={l.data} />
            ) : (
              <components.LabelPill label={l.data} />
            ),
        }))}
        setLabels={changeAreaLabels}
        isSingle={true}
        displayName="Product Area"
        addCTA={() =>
          window.open(
            `${utils.strings.getAppOrigin()}/w/${
              active ? active.slug : null
            }/settings/labels`
          )
        }
      />
      <IssueLabels
        options={labelOptions.filter((l) => l.data.type === LabelType.None)}
        labels={(noneLabels || []).map((l) => ({
          ...l,
          label:
            l.data.type === LabelType.Area ? (
              <ProductArea label={l.data} />
            ) : (
              <components.LabelPill label={l.data} />
            ),
        }))}
        setLabels={setNoneLabels}
        addCTA={() =>
          window.open(
            `${utils.strings.getAppOrigin()}/w/${
              active ? active.slug : null
            }/settings/labels`
          )
        }
      />
      {isExposed && (
        <IssueVisibility visible={visible} setVisible={setVisible} />
      )}
      <ModalFooter>
        <components.ButtonGroup right={true}>
          <components.Button secondary={true} onClick={close}>
            Cancel
          </components.Button>
          <components.Tooltip
            message={
              <>
                <code>{environment.mac ? '⌘' : 'Ctrl'}</code>
                <code>Enter</code>
              </>
            }
          >
            <components.Button onClick={onSave}>OK</components.Button>
          </components.Tooltip>
        </components.ButtonGroup>
      </ModalFooter>
    </StyledModal>
  );
};

export default IssueEditModal;
