import React, { useContext, useMemo } from 'react';
import { utils, icons } from 'herald-fe-shared';
import { ExternalLink } from 'components/ExternalLink';
import { WorkspaceContext } from 'components/context/WorkspaceWrapper';

interface IViewIssueButtonProps {
  id: string;
}

export const ViewIssueButton: React.FC<IViewIssueButtonProps> = (props) => {
  const { active } = useContext(WorkspaceContext);
  const { id } = props;
  const url = useMemo(
    () => `${utils.strings.getAppOrigin()}/w/${active?.slug}/issues/${id}`,
    [id, active]
  );
  return (
    <ExternalLink url={url} className="issue__button">
      <icons.ExternalLink className="view-issue__link__icon" />
    </ExternalLink>
  );
};

export default ViewIssueButton;
