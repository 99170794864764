import React from 'react';

import AuthWrapper from 'components/context/AuthWrapper';
import WorkspaceWrapper from 'components/context/WorkspaceWrapper';
import LoadingWrapper from 'components/context/LoadingWrapper';
import SnippetWrapper from 'components/context/SnippetWrapper';
import ParsedContactWrapper from 'components/context/ParsedContactWrapper';
import ModalWrapper from 'components/context/ModalWrapper';

const Wrapper: React.FC = (props) => (
  <LoadingWrapper>
    <AuthWrapper>
      <WorkspaceWrapper>
        <ParsedContactWrapper>
          <SnippetWrapper>
            <ModalWrapper>{props.children}</ModalWrapper>
          </SnippetWrapper>
        </ParsedContactWrapper>
      </WorkspaceWrapper>
    </AuthWrapper>
  </LoadingWrapper>
);

export default Wrapper;
